.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 16px;
}

.empty {
    text-align: center;
}

@media (min-width: 500px) {
    .wrapper {
        font-size: 24px;
    }
}

@media (min-width: 720px) {
    .wrapper {
        font-size: 30px;
    }
}
