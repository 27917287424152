.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}
.toggle-switch input[type="checkbox"] {
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.toggle-switch .switch {
    position: absolute;
    background-color: var(--c-theme-background);
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-color: var(--c-theme-color);
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: var(--c-theme-background);
}
.toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: var(--c-theme-color);
}
