.round {
    display: block;
    font-size: 1em;
    text-align: center;
}

.list {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 30px;
    text-align: center;
}

