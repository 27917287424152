.wrap {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.wrap button {
    margin: 0 0.2em;
}
