/**
Generic
**/

.infowrap {
    font-size: 0.95em;
}

.infowrap p {
    margin-top: 0.8em;
}

.infowrap p:first-child {
    margin-top: 0;
}

.infowrap p:empty {
    display: none;
}

.infowrap h2,
.infowrap h3 {
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.07em;
}

.infowrap h2:first-child,
.infowrap h3:first-child {
    margin-top: 0;
}

.infowrap h2 {
    font-size: 1.6em;
    margin-top: 1.75em;
    margin-bottom: 0.875em;
    line-height: 1.1;
}

.infowrap h3 {
    font-size: 1.3em;
    margin-bottom: 0.5em;
    margin-top: 1.07692em;
}

.infowrap ul {
    list-style-position: outside;
    margin-left: 1.4em;
}

.infowrap ul {
    list-style-type: disc;
}

.infowrap ul ul {
    list-style-type: circle;
}

/**
Intro
**/
.intro {

}

.intro img {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-bottom: 1em;
    border-radius: 200vh;
    overflow: hidden;
}

@media (min-width: 768px) {
    .intro {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .intro img {
        margin: 0;
        margin-right: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        width: 125px;
        height: 125px;
    }
}

.sns {
    margin-top: 0.2em;
    display: flex;
    align-items: center;
    font-size: 2.2em;
    line-height: 1;
}

.sns a {
    transform: scale(1.05);
    transition: transform 150ms ease;
}

.sns a:not(:first-child) {
    margin-left: 0.6em;
}

.sns a:hover,
.sns a:focus,
.sns a:active {
    color: var(--c-theme-color);
    background: transparent;
    text-decoration: none;
    transform: scale(1.15);
}