.wrap {
    position: relative;
}

.wrap:after {
    content: "";
    display: block;
    position: absolute;
    /* 2px to factor in border */
    top: 2px;
    bottom: 2px;
    right: 2px;
    width: 2em;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(var(--c-background-rgb), 0) 0%, var(--c-background) 100%)
        no-repeat center right / contain;
}

.select {
    display: block;
    appearance: none;
    max-width: 10em;
    padding: 0.4em 0.6em;
    padding-right: 0;

    font-weight: 300;
    color: var(--c-theme-color);
    border: 2px solid var(--c-theme-color);
    background: var(--c-background);
    border-radius: 2px;
    cursor: pointer;
}

.select:focus {
    outline: none;
    border-color: var(--c-theme-focus);
}

.option {
    text-align: center;
}
